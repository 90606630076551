<template>
  <div class="course">
    <div id="printBox">
      <!-- <div class="_title">海之贝血液透析中心</div> -->
      <div class="_tit">{{ printData.type_name }}</div>
      <div class="_user flex-center">
        <div class="_item name">姓名：{{ userInfo.name }}</div>
        <div class="_item">
          性别：{{ gitDictVal('101000000', userInfo.dict_sex) }}
        </div>
        <div class="_item">年龄：{{ userInfo.age }}岁</div>
        <div class="_item">
          科室：{{ gitDictVal('156000000', userInfo.dept) }}
        </div>
        <div class="_item">透析编号：{{ userInfo.case_no }}</div>
      </div>
      <div class="_tip" v-html="printData.course"></div>
      <div class="_bottom flex-between">
        <div class="_name">医生签名：{{ printData.doctor_name }}</div>
        <div class="_time">{{ printData.set_time }}</div>
      </div>
    </div>
    <div class="course-top flex-center">
      <div class="_item name">{{ userInfo.name }}</div>
      <div class="_item">{{ gitDictVal('101000000', userInfo.dict_sex) }}</div>
      <div class="_item">{{ userInfo.age }}岁</div>
      <div class="_item">透析号：{{ userInfo.case_no }}</div>
    </div>
    <div class="search-top flex-between">
      <el-form
        :inline="true"
        :model="formData"
        ref="searchForm"
        class="demo-form-inline w-120"
      >
        <el-form-item label="病程类型">
          <el-select v-model="searchData.type" clearable placeholder="请选择">
            <el-option
              v-for="item in courseType"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="记录日期">
          <el-date-picker
            v-model="timeData"
            type="daterange"
            value-format="YYYY-MM-DD"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="排序">
          <el-select v-model="searchData.tyep" clearable placeholder="请选择">
            <el-option label="时间降序" value="desc"></el-option>
            <el-option label="时间升序" value="asc"></el-option>
          </el-select>
        </el-form-item>
      </el-form>

      <div class="search-but">
        <el-button class="color-vice" @click="handleSearch" type="primary">
          查询
        </el-button>
        <el-button @click="addCoures" class="color-vice" type="primary">
          新增
        </el-button>
        <!-- <el-button @click="handleSearch" class="color-vice" type="primary">
          批量打印
        </el-button> -->
      </div>
    </div>
    <div class="course-cont">
      <div class="course-item" v-for="item in courseList" :key="item.id">
        <div class="_top flex-between">
          <div class="info flex-start">
            <div class="_lable">日期：{{ item.set_time }}</div>
            <div class="_lable type">
              类型：{{ gitCourseTypeVal(item.type) }}
            </div>
            <div class="_lable">诊断医生：{{ item.doctoer_name }}</div>
          </div>
          <div class="butBox flex-center">
            <i class="fa fa-edit" @click="editCourse(item)"></i>
            <i v-print="print" @click="prints(item)" class="fa fa-print"></i>
            <i class="fa fa-trash-o" @click="delCourse(item)"></i>
          </div>
        </div>
        <div class="_cont" v-html="item.course"></div>
      </div>
      <div class="pagination-box">
        <el-pagination
          v-model:currentPage="pageData.currentPage"
          v-model:page-size="pageData.pageSize"
          :page-sizes="[10, 20, 50, 100]"
          :background="true"
          layout="total, sizes, prev, pager, next, jumper"
          :total="pageData.totalSum"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
        >
        </el-pagination>
      </div>
    </div>

    <BaseDialog
      title="病程登记"
      :isshow="modCoures"
      @handleShow="
        (val) => {
          modCoures = val
        }
      "
      width="800px"
      height="auto"
    >
      <div class="schemeBox">
        <div class="user-info flex-center">
          <div class="_item name">{{ userInfo.name }}</div>
          <div class="_item">
            {{ gitDictVal('101000000', userInfo.dict_sex) }}
          </div>
          <div class="_item">{{ userInfo.age }}岁</div>
          <div class="_item">透析号：{{ userInfo.case_no }}</div>
        </div>
        <div class="userItem">
          <el-form
            ref="ruleFormRef"
            :model="ruleForm"
            :rules="Rule"
            :inline="true"
            label-width="84px"
            label-position="right"
            class="demo-ruleForm col-333"
          >
            <el-form-item label="记录日期" prop="set_time">
              <el-date-picker
                v-model="ruleForm.set_time"
                type="date"
                value-format="YYYY-MM-DD"
                placeholder="请选择时间"
              ></el-date-picker>
            </el-form-item>
            <el-form-item label="记录医生" prop="doctor_id">
              <el-select v-model="ruleForm.doctor_id" placeholder="请选择">
                <el-option
                  v-for="item in userList"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="病程类型" prop="type">
              <el-select
                v-model="ruleForm.type"
                @change="courseTypeChange"
                placeholder="请选择"
              >
                <el-option
                  v-for="item in courseType"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item style="width: 100%" label="选择模板">
              <div class="tagBox">
                <el-tag
                  style="cursor: pointer"
                  v-for="(item, index) in typeTpl"
                  :key="index"
                  @click="tagTpl(item)"
                >
                  {{ item.name }}
                </el-tag>
              </div>
            </el-form-item>
            <el-form-item style="width: 100%" prop="course" label="病程详情">
              <tinymce
                v-model:value="ruleForm.course"
                :height="250"
                width="100%"
                @input="tinymceInput"
              />
            </el-form-item>
          </el-form>
        </div>
      </div>
      <template #footer>
        <div class="dialog-footer">
          <el-button
            class="color-cancel"
            type="primary"
            @click="modCoures = false"
          >
            取消
          </el-button>
          <el-button
            @click="saveCoures"
            :loading="saveLoad"
            class="color-determine"
            type="primary"
          >
            确定
          </el-button>
        </div>
      </template>
    </BaseDialog>
  </div>
</template>

<script setup>
import { ref, reactive, nextTick, defineExpose } from 'vue'
import { useRoute } from 'vue-router'
import service from '@/utils/request'
import { ElMessage, ElMessageBox } from 'element-plus'
import BaseDialog from '@/components/Dialog/index.vue'
import Tinymce from '@/components/Tinymce'
import { gitDictList } from '@/utils/tool.js'

const route = useRoute()
const searchData = reactive({
  patient_id: route.params.id,
  current: 1,
  size: 10,
})
const pageData = reactive({
  currentPage: 1,
  pageSize: 10,
  totalSum: 0,
})
const timeData = ref([])
const ruleForm = ref({})
const userInfo = ref({})
const courseList = ref([])
const dictList = ref([])
const modCoures = ref(false)
const ruleFormRef = ref(null)
const Rule = reactive({
  set_time: [{ required: true, message: '请选择记录日期', trigger: 'change' }],
  doctor_id: [{ required: true, message: '请选择记录医生', trigger: 'change' }],
  type: [{ required: true, message: '请选择病程类型', trigger: 'change' }],
  course: [{ required: true, message: '请输入病程详情', trigger: 'change' }],
})
const print = reactive({
  id: 'printBox',
  popTitle: '病程记录',
})

const initData = () => {
  getDict()
  getInfoData()
  getCourseType()
  getUserList()
  handleSearch()
}
defineExpose({
  initData,
})
const getDict = async () => {
  const dictLists = await gitDictList(['101000000', '156000000'])
  dictList.value = {
    ...dictList.value,
    ...dictLists,
  }
}
const handleSizeChange = (val) => {
  pageData.pageSize = val
  getDataList()
}

const handleCurrentChange = (val) => {
  pageData.currentPage = val
  getDataList()
}
const handleSearch = () => {
  pageData.currentPage = 1
  getDataList()
}
const getDataList = async () => {
  searchData.current = pageData.currentPage
  searchData.size = pageData.pageSize
  if (timeData.value && timeData.value.length > 0) {
    searchData.start_date = timeData.value[0]
    searchData.end_date = timeData.value[1]
  } else {
    searchData.start_date = ''
    searchData.end_date = ''
  }
  let res = await service.post('/api/course/getPatientCourseList', searchData)
  if (res.code === 0) {
    courseList.value = res.data.list
    pageData.totalSum = res.data.total
  }
}
const courseType = ref([])
const getCourseType = async () => {
  let res = await service.post('/api/course/getCourseType')
  if (res.code === 0) {
    let par = []
    for (let i in res.data) {
      let row = {
        id: res.data[i],
        name: i,
      }
      par.push(row)
    }
    courseType.value = par
  }
}
const userList = ref([])
const getUserList = async () => {
  let res = await service.post('/api/user/list', { role_id: 1 })
  if (res.code === 0) {
    userList.value = res.data.records
  }
}

const addCoures = () => {
  modCoures.value = true
  ruleForm.value = {}
  nextTick(() => {
    ruleFormRef.value.clearValidate()
  })
}
const editCourse = (item) => {
  ruleForm.value = JSON.parse(JSON.stringify(item))
  ruleForm.value.type = ruleForm.value.type.toString()
  modCoures.value = true
  nextTick(() => {
    ruleFormRef.value.clearValidate()
  })
}
const delCourse = (item) => {
  ElMessageBox.confirm('删除后无法撤销，确认删除吗？', '操作提示', {
    confirmButtonText: '确认',
    cancelButtonText: '取消',
    type: 'warning',
  })
    .then(async () => {
      let res = await service.post('/api/course/deletePatientCourse', {
        id: item.id,
      })
      if (res.code === 0) {
        ElMessage.success(res.msg)
        getDataList()
      }
    })
    .catch(() => {})
}

const gitDictVal = (item, val) => {
  if (!dictList.value[item]) return
  let name = ''
  dictList.value[item].map((obj) => {
    if (obj.code === val) {
      name = obj.name
    }
  })
  return name
}
const gitCourseTypeVal = (type) => {
  let name = ''
  courseType.value.map((obj) => {
    if (obj.id == type) {
      name = obj.name
    }
  })
  return name
}
const printData = ref({})
const prints = (item) => {
  printData.value = item
}

const getInfoData = async () => {
  let res = await service.post('/api/patient/get_patient_detail', {
    patient_id: route.params.id,
  })
  if (res.code === 0) {
    userInfo.value = res.data
  }
}
const typeTpl = ref([])
const courseTypeChange = async (row) => {
  let res = await service.post('/api/course/getTypeTpl', {
    type: row,
  })
  if (res.code === 0) {
    typeTpl.value = res.data
  }
}
const tagTpl = (item) => {
  ruleForm.value.course = item.course
}
// 富文本内容
const tinymceInput = (e) => {
  ruleForm.value.course = e
}

// 保存
const saveLoad = ref(false)
const saveCoures = () => {
  ruleForm.value.patient_id = route.params.id
  ruleFormRef.value.validate(async (valid) => {
    if (valid) {
      saveLoad.value = true
      let url = ''
      if (ruleForm.value.id) {
        url = '/api/course/edtiPatientCourse'
      } else {
        url = '/api/course/createPatientCourse'
      }
      let res = await service.post(url, ruleForm.value)
      saveLoad.value = false
      if (res.code === 0) {
        ElMessage.success(res.msg)
        getDataList()
        modCoures.value = false
      }
    }
  })
}
</script>

<style scoped lang="scss">
.course {
  &:deep(.el-date-editor) {
    width: 220px;
  }
}
.search-top {
  padding: 16px;
}
.course-top {
  margin-top: 10px;
  height: 40px;
  padding: 0 16px;
  border-bottom: 1px solid #c6c6c6;
  border-top: 1px solid #c6c6c6;
  ._item {
    margin-right: 42px;
    &.name {
      border-left: 4px solid #3166ae;
      padding-left: 8px;
    }
  }
}
.course-cont {
  padding: 0 16px;
  .course-item {
    border-bottom: 1px solid #c6c6c6;
    margin-bottom: 32px;
    .info {
      font-size: 16px;
      ._lable {
        margin-right: 32px;
        font-weight: bold;
        &.type {
          margin-right: 50px;
        }
      }
    }
    .butBox {
      color: #3166ae;
      font-size: 18px;
      .fa {
        margin-left: 22px;
        cursor: pointer;
      }
    }
    ._cont {
      padding-left: 20px;
      line-height: 1.5;
      margin-top: 22px;
      margin-bottom: 32px;
    }
  }
}
.schemeBox {
  .user-info {
    margin: 16px 0;
    height: 40px;
    padding: 0 16px;
    background-color: #e6ebf2;
    ._item {
      margin-right: 42px;
      &.name {
        border-left: 4px solid #3166ae;
        padding-left: 8px;
      }
    }
  }
}
#printBox {
  color: #333333;
  width: 100%;
  position: absolute;
  z-index: -1;
  ._title {
    font-size: 26px;
    text-align: center;
  }
  ._tit {
    text-align: center;
    margin: 20px 0;
  }
  ._user {
    justify-content: space-between;
    padding: 8px 16px;
    border-bottom: 1px solid #000000;
  }
  ._tip {
    padding: 8px 16px;
  }
  ._bottom {
    margin-top: 10px;
    padding: 0 16px;
  }
}
</style>
